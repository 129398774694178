/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/6/2020
 * @Example
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../../redux/W3X/W39F1025/W39F1025_actions";
import { delay } from "redux-saga";

export default function W39F1025Sagas() {
    return [
        watchGetEvaluationType(),
        watchGetDetail(),
        watchAdd(),
        watchEdit()
    ];
}

//get evaluation type..
export function* getEvaluationType(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1025/load-evaluation-type', data.params);
        if (response && response.data) {
            yield put({
                type: types.W39F1025_GET_EVALUATION_TYPE_SUCCESS,
                data: response.data,
            });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get evaluation type error');
    }

}

export function* watchGetEvaluationType() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1025_GET_EVALUATION_TYPE, getEvaluationType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get detail..
export function* getDetail(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1025/load-detail', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get detail error');
    }

}

export function* watchGetDetail() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1025_GET_DETAIL, getDetail);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//add..
export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1025/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add error');
    }

}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1025_ADD, add);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//add..
export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1025/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add error');
    }

}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1025_EDIT, edit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}