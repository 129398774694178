/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/16/2020
 * @Example
 */

export const types = {
	W38F2001_LOAD_TRAINING_FIELD                 : 'W38F2001_LOAD_TRAINING_FIELD',
	W38F2001_LOAD_TRAINING_FIELD_SUCCESS         : 'W38F2001_LOAD_TRAINING_FIELD_SUCCESS',
	W38F2001_LOAD_TRAINING_COURSE                : 'W38F2001_LOAD_TRAINING_COURSE',
	W38F2001_LOAD_TRAINING_COURSE_DETAIL         : 'W38F2001_LOAD_TRAINING_COURSE_DETAIL',
	W38F2001_LOAD_TRAINING_COURSE_DETAIL_SUCCESS : 'W38F2001_LOAD_TRAINING_COURSE_DETAIL_SUCCESS',
	W38F2001_LOAD_FORM                           : 'W38F2001_LOAD_FORM',
	W38F2001_LOAD_FORM_SUCCESS                   : 'W38F2001_LOAD_FORM_SUCCESS',
	W38F2001_LOAD_GRID                           : 'W38F2001_LOAD_GRID',
	W38F2001_LOAD_GRID_SUCCESS                   : 'W38F2001_LOAD_GRID_SUCCESS',
	W38F2001_ADD                                 : "W38F2001_ADD",
	W38F2001_EDIT                                : "W38F2001_EDIT",
	W38F2001_ONSAVE                              : "W38F2001_ONSAVE",
	W38F2001_RESET_PROPS                         : "W38F2001_RESET_PROPS",
	W38F2001_REMOVE_ITEM                         : 'W38F2001_REMOVE_ITEM',
	W38F2001_REMOVE_ITEM_SUCCESS                 : 'W38F2001_REMOVE_ITEM_SUCCESS',
}

export function loadCboTrainingField(params, cb) {
    return {
        type: types.W38F2001_LOAD_TRAINING_FIELD,
        params,
        cb
    }
}

export function loadTrainingCourse(params, cb) {
    return {
        type: types.W38F2001_LOAD_TRAINING_COURSE,
        params,
        cb
    }
}

export function loadTrainingCourseDetail(params, cb) {
    return {
        type: types.W38F2001_LOAD_TRAINING_COURSE_DETAIL,
        params,
        cb
    }
}

export function loadForm(params, cb) {
    return {
        type: types.W38F2001_LOAD_FORM,
        params,
        cb
    }
}
export function loadGrid(params, cb) {
    return {
        type: types.W38F2001_LOAD_GRID,
        params,
        cb
    }
}

export function add(params, cb) {
    return {
        type: types.W38F2001_ADD,
        params,
        cb
    }
}

export function edit(params, cb) {
    return {
        type: types.W38F2001_EDIT,
        params,
        cb
    }
}

export function resetProps(DepartmentID) {
    return {
        type: types.W38F2001_RESET_PROPS,
        DepartmentID: DepartmentID
    }
}

export function removeItem(params, cb) {
    return {
        type: types.W38F2001_REMOVE_ITEM,
        params,
        cb
    }
}
