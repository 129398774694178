export const types = {
    W39F1020_LOAD_GRID: 'W39F1020_LOAD_GRID',
    W39F1020_DELETE: 'W39F1020_DELETE'
};

export function getGrid(params,cb) {
    return{
        type: types.W39F1020_LOAD_GRID,
        params,
        cb
    }
}

export function deleteDataGrid(params,cb) {
    return{
        type: types.W39F1020_DELETE,
        params,
        cb
    }
}

