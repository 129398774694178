/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/30/2020
 * @Example
 */

export const types = {
	W39F1000_LOAD_GRID: 		  'W39F1000_LOAD_GRID',
	W39F1000_LOAD_GRID_SUCCESS:   'W39F1000_LOAD_GRID_SUCCESS',
	W39F1000_DELETE_GRID:		  'W39F1000_DELETE_GRID'
}

export function loadDataGrid(params, cb) {
    return {
        type: types.W39F1000_LOAD_GRID,
        params,
        cb
    }
}

export function deleteDataGrid(params, cb) {
	return {
		type: types.W39F1000_DELETE_GRID,
		params,
		cb
	}
}
