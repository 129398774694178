/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/16/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W38F2001_actions";
import { delay } from "redux-saga";

export default function W38F2001Sagas() {
    return [
        watchLoadTrainingField(),
        watchLoadTrainingCourse(),
        watchLoadTrainingCourseDetail(),
        watchLoadForm(),
        watchLoadGrid(),
        watchAdd(),
        watchEdit(),
        watchRemoveItem()
    ];
}

export function* loadTrainingField(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2001/load-training-field', data.params);
        if (response && response.data) {
            yield put({
                type: types.W38F2001_LOAD_TRAINING_FIELD_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('Load combo training field error!');
    }

}

export function* watchLoadTrainingField() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2001_LOAD_TRAINING_FIELD, loadTrainingField);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadTrainingCourse(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2001/load-training-course', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('Load combo training course error!');
    }

}

export function* watchLoadTrainingCourse() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2001_LOAD_TRAINING_COURSE, loadTrainingCourse);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadTrainingCourseDetail(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2001/load-training-course-detail', data.params);
        if (response && response.data) {
            yield put({
                type: types.W38F2001_LOAD_TRAINING_COURSE_DETAIL_SUCCESS,
                data: response.data[0]
            });
            data.cb && data.cb(null, response.data[0]);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('Load combo training course error!');
    }

}

export function* watchLoadTrainingCourseDetail() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2001_LOAD_TRAINING_COURSE_DETAIL, loadTrainingCourseDetail);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2001/load-form', data.params);
        if (response && response.data) {
            yield put({
                type: types.W38F2001_LOAD_FORM_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2001_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2001/load-grid', data.params);
        if (response && response.data) {
            // response.data = [
            //     {
            //         EmployeeID: '03991',
            //         EmployeeName: 'Hải Yến',
            //         DutyName: 'Người mẫu ảnh'
            //     },
            //     {
            //         EmployeeID: '-023',
            //         EmployeeName: 'Thuợng Đình',
            //         DutyName: 'Giám đốc kinh doanh'
            //     }
            // ];
            yield put({
                type: types.W38F2001_LOAD_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2001_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/W38F2001/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(
            types.W38F2001_ADD, add
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/W38F2001/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest( types.W38F2001_EDIT, edit );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* removeItem(data) {
    try {
        yield delay(300);
        yield put({type: types.W38F2001_REMOVE_ITEM_SUCCESS, data: data.params});
        data.cb && data.cb(null);
    }
    catch (e) {
        console.log('Remove item error');
    }

}

export function* watchRemoveItem() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2001_REMOVE_ITEM, removeItem);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
