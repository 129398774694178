/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/16/2020
 * @Example
 */

import { types } from "./W38F2000_actions";

const initialState = {
    getCboTraining: [],
    dataGrid: []
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W38F2000_GET_COMBO_TRAINING_SUCCESS: {
            return {
                ...state,
                getCboTraining: action.data
            };
        }
        case types.W38F2000_LOAD_DATA_GRID_SUCCESS: {
            // if (action.data.rows.length) {
            //     action.data.rows[0].ApproverName = 'Người ơi';
            //     action.data.rows[0].ProCost = 2345678000;
            //     action.data.rows[0].ProCurrencyName = 'VND';
            //     action.data.rows[0].AppStatusName = 'Đã duyệt';
            // }
            return {
                ...state,
                dataGrid: action.data
            };
        }
        default:
            return state;
    }
}
