/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/02/2020
 * @Example
 */

export const types = {
    W39F1005_LOAD_TRANS_TYPE: 		  	  'W39F1005_LOAD_TRANS_TYPE',
    W39F1005_LOAD_DETAIL:   		        'W39F1005_LOAD_DETAIL',
    W39F1005_LOAD_DETAIL_SUCCESS:       'W39F1005_LOAD_DETAIL_SUCCESS',
    W39F1005_ADD:                       'W39F1005_ADD',
    W39F1005_EDIT:                      'W39F1005_EDIT',
    W39F1005_REMOVE_ITEM:               'W39F1005_REMOVE_ITEM',
    W39F1005_REMOVE_ITEM_SUCCESS:       'W39F1005_REMOVE_ITEM_SUCCESS',
    W39F1005_UPDATE_LOCK:               'W39F1005_UPDATE_LOCK',
};

export function loadCboTransType(params, cb) {
    return {
        type: types.W39F1005_LOAD_TRANS_TYPE,
        params,
        cb
    }
}

export function loadDetail(params, cb) {
    return {
        type: types.W39F1005_LOAD_DETAIL,
        params,
        cb
    }
}

export function add(params, cb) {
    return {
        type: types.W39F1005_ADD,
        params,
        cb
    }
}

export function edit(params, cb) {
    return {
        type: types.W39F1005_EDIT,
        params,
        cb
    }
}

export function removeItem(params, cb) {
    return {
        type: types.W39F1005_REMOVE_ITEM,
        params,
        cb
    }
}

export function updateLock(params, cb) {
    return {
        type: types.W39F1005_UPDATE_LOCK,
        params,
        cb
    }
}
