/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 31/12/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W39F2035_actions";

export default function W39F2035Sagas() {
    return [
        watchLoadGrid(),
        watchApproval(),
        watchLoadCboTransType(),
        watchCheckPage()
    ];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f2035/get-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F2035_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* approval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f2035/confirm', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('approval error');
    }

}

export function* watchApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W39F2035_APPROVAL, approval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadCboTransType(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1005/load-evaluation-type', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('Load trans type error');
    }
}

export function* watchLoadCboTransType() {
    while (true) {
        const watcher = yield takeLatest(types.W39F2035_LOAD_CBO_TRANS_TYPE, loadCboTransType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* checkPage(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w39f2035/check');
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('check page error');
    }
}

export function* watchCheckPage() {
    while (true) {
        const watcher = yield takeLatest(types.W39F2035_CHECK_PAGE, checkPage);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}