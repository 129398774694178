/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 31/12/2021
 * @Example
 */
export const types = {
    W39F2035_LOAD_GRID: "W39F2035_LOAD_GRID",
    W39F2035_APPROVAL: "W39F2035_APPROVAL",
    W39F2035_CHECK_PAGE: "W39F2035_CHECK_PAGE",
    W39F2035_LOAD_CBO_TRANS_TYPE: "W39F2035_LOAD_CBO_TRANS_TYPE",
};

export function loadGrid(params, cb) {
    return {
        type: types.W39F2035_LOAD_GRID,
        params,
        cb
    }
}

export function approval(params, cb) {
    return {
        type: types.W39F2035_APPROVAL,
        params,
        cb
    }
}

export function loadCboTransType(params, cb) {
    return {
        type: types.W39F2035_LOAD_CBO_TRANS_TYPE,
        params,
        cb
    }
}

export function checkPage(cb) {
    return {
        type: types.W39F2035_CHECK_PAGE,
        cb
    }
}

