/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 7/4/2020
 * @Example
 */

import { types } from "./W39F1010_actions";

const initialState = {
    getFormGrid: [],
    getTransType: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1010_GET_FORM_GRID_SUCCESS: {
            return { ...state, getFormGrid: action.data };
        }
        case types.W39F1010_GET_TRANS_TYPE_SUCCESS: {
            return { ...state, getTransType: action.data };
        }
        default: {
            return state;
        }
    }
}
