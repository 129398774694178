/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/13/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W39F2001_actions";
import { delay } from "redux-saga";

export default function W39F2001Sagas() {
    return [
        watchGetContractTypes(),
        watchGetDataGrids(),
    ];
}

export function* getContractTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w39f2001/load-contract-type');
        if (response && response.data) {
            yield put({ type: types.W39F2001_GET_CONTRACT_TYPES_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get contract types error');
    }

}

export function* watchGetContractTypes() {
    while (true) {
        const watcher = yield takeLatest(types.W39F2001_GET_CONTRACT_TYPES, getContractTypes);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDataGrids(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f2001/load-grid', data.params);
        if (response && response.data) {
            yield put({ type: types.W39F2001_GET_DATAGRID_SUCCESS, data: [] });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data grids error');
    }

}

export function* watchGetDataGrids() {
    while (true) {
        const watcher = yield takeLatest(types.W39F2001_GET_DATAGRID, getDataGrids);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
