/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 7/4/2020
 * @Example
 */

export const types = {
    W39F1010_GET_FORM_GRID: "W39F1010_GET_FORM_GRID",
    W39F1010_GET_FORM_GRID_SUCCESS: "W39F1010_GET_FORM_GRID_SUCCESS",
    W39F1010_GET_TRANS_TYPE: "W39F1010_GET_TRANS_TYPE",
    W39F1010_GET_TRANS_TYPE_SUCCESS: "W39F1010_GET_TRANS_TYPE_SUCCESS",
    W39F1010_GET_STATUS: "W39F1010_GET_STATUS",
};

export function getFormGrid(params, cb) {
    return {
        type: types.W39F1010_GET_FORM_GRID,
        cb,
        params,
    };
}

export function getTransType(cb) {
    return {
        type: types.W39F1010_GET_TRANS_TYPE,
        cb,
    };
}


export function getStatus(cb) {
    return {
        type: types.W39F1010_GET_STATUS,
        cb,
    };
}

