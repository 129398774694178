/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/16/2020
 * @Example
 */

export const types = {
	W38F2000_GET_COMBO_TRAINING        : 'W38F2000_GET_COMBO_TRAINING'        ,
	W38F2000_GET_COMBO_TRAINING_SUCCESS: 'W38F2000_GET_COMBO_TRAINING_SUCCESS',
	W38F2000_LOAD_DATA_GRID            : 'W38F2000_LOAD_DATA_GRID'            ,
	W38F2000_LOAD_DATA_GRID_SUCCESS    : 'W38F2000_LOAD_DATA_GRID_SUCCESS'    ,
	W38F2000_DELETE_GRID               : 'W38F2000_DELETE_GRID'
}

export function getCboTraining(params, cb) {
    return {
        type: types.W38F2000_GET_COMBO_TRAINING,
        params,
        cb
    }
}

export function loadDataGrid(params, cb) {
    return {
        type: types.W38F2000_LOAD_DATA_GRID,
        params,
        cb
    }
}

export function deleteGrid(params, cb) {
	return {
		type: types.W38F2000_DELETE_GRID,
		params,
		cb
	}
}
