/***********************************************************************/
/*                                                                     */
/*   Copyright (C) 2020. All rights reserved                           */
/*   License    : https://diginet.com.vn                               */
/*   Author     : RocaChien                                            */
/*                                                                     */
/*   Created    : 09-07-2020 10:13:05.                                 */
/*   Modified   : 09-07-2020 10:13:05.                                 */
/*                                                                     */
/***********************************************************************/

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W39F1011_actions";
import { delay } from "redux-saga";

export default function W39F1011Sagas() {
  return [
    watchMasterData(),
    watchCriterionGroup(),
    watchCriterionCombo(),
    watchCriterionDetail(),
    watchComboResults(),
    watchSaveResult(),
    watchHistoryApproval(),
    watchGetResult(),
  ];
}

export function* getComboResults(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w39f1011/load-combo-result", data.params);
    if (response && response.data) {
      yield put({ type: types.W39F1011_LOAD_COMBO_RESULTS_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.error("== getComboResults", e);
  }
}

export function* watchComboResults() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_LOAD_COMBO_RESULTS, getComboResults);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getMasterData(data) {
  try {
    yield delay(300);
    yield put({ type: types.W39F1011_LOAD_MASTER_DATA_SUCCESS, data: {} });
    const response = yield Api.put("/w39f1011/load-master", data.params);
    if (response && response.data) {
      yield put({ type: types.W39F1011_LOAD_MASTER_DATA_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.error("== getMasterData", e);
  }
}

export function* watchMasterData() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_LOAD_MASTER_DATA, getMasterData);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCriterionGroup(data) {
  try {
    yield delay(300);
    yield put({ type: types.W39F1011_LOAD_CRITERION_GROUP_SUCCESS, data: [] });
    const response = yield Api.get("/w39f1011/load-app-criterion-group", data.params);
    if (response && response.data) {
      yield put({ type: types.W39F1011_LOAD_CRITERION_GROUP_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.error("== getCriterionGroup", e);
  }
}

export function* watchCriterionGroup() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_LOAD_CRITERION_GROUP, getCriterionGroup);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCriterionCombo(data) {
  try {
    yield delay(300);
    yield put({ type: types.W39F1011_LOAD_CRITERION_COMBO_SUCCESS, data: [] });
    const response = yield Api.put("/w39f1011/load-combo", data.params);
    if (response && response.data) {
      yield put({ type: types.W39F1011_LOAD_CRITERION_COMBO_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.error("== getCriterionCombo", e);
  }
}

export function* watchCriterionCombo() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_LOAD_CRITERION_COMBO, getCriterionCombo);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCriterionDetail(data) {
  try {
    yield delay(300);
    yield put({type: types.W39F1011_LOAD_CRITERION_DETAIL_SUCCESS, data: []});
    const response = yield Api.put("/w39f1011/load-detail", data.params);
    if (response && response.data) {
      yield put({type: types.W39F1011_LOAD_CRITERION_DETAIL_SUCCESS, data: response.data});
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.error("== getCriterionDetail", e);
  }
}

export function* watchCriterionDetail() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_LOAD_CRITERION_DETAIL, getCriterionDetail);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getHistoryApproval(data) {
  try {
    yield delay(300);
    yield put({type: types.W39F1011_LOAD_HISTORY_APPROVAL_SUCCESS, data: []});
    const response = yield Api.put("/w39f1011/load-group-history-approval", data.params);
    if (response && response.data) {
      yield put({type: types.W39F1011_LOAD_HISTORY_APPROVAL_SUCCESS, data: response.data});
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.error("== getHistoryApproval", e);
  }
}

export function* watchHistoryApproval() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_LOAD_HISTORY_APPROVAL, getHistoryApproval);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getSaveResult(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w39f1011/save", data.params);
    if (response && response.data) {
      yield put({ type: types.W39F1011_SAVE_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("== getSaveResult: ", e);
  }
}

export function* watchSaveResult() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_SAVE, getSaveResult);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getResult(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w39f1011/get-result", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get result error ", e);
  }
}

export function* watchGetResult() {
  while (true) {
    const watcher = yield takeLatest(types.W39F1011_GET_RESULT, getResult);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
