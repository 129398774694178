/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/6/2020
 * @Example 
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../../redux/W3X/W39F1020/W39F1020_actions";
import { delay } from "redux-saga";

export default function W39F1020Sagas() {
    return [
        watchGetGrid(),
        watchDeleteDataGrid()
    ];
}

//get gird..
export function* getGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1020/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get columns error');
    }

}

export function* watchGetGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1020_LOAD_GRID, getGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//delete..
export function* deleteDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1020/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get columns error');
    }

}

export function* watchDeleteDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1020_DELETE, deleteDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}